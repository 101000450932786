import { Grid, Typography, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const StatusBadgeContainer = styled(Grid)(
	({ theme, variant, status, customColor }) => ({
		display: "grid", // Display as grid container
		gridTemplateColumns: "auto auto", // Two columns: one for the dot, one for the status message
		alignItems: "center", // Align items vertically
		width: "fit-content", // Allow container to adjust its width based on content
		padding: "4px 8px", // Padding
		borderRadius: "16px", // Border radius
		backgroundColor: customColor
			? customColor
			: variant === "contain"
				? getbackgroundColor(status)
				: "transparent",
		justifyContent: "center",
	})
);

const Dot = styled("div")(({ theme, status, variant, statusType }) => ({
	width: "6px", // Fixed width
	height: "6px", // Fixed height
	backgroundColor: getStatusColor(status, variant, theme, statusType), // Dynamically get color based on status
	borderRadius: "50%", // Make it a circle
	marginRight: 5,
}));

const StatusMessage = styled(Typography)(
	({ theme, status, statusVariant, statusType }) => ({
		color: getStatusColor(status, statusVariant, theme, statusType),
		textAlign: "left",
	})
);

const getbackgroundColor = (status) => {
	switch (status) {
		case "LEG_NEW":
		case "DR_NEW":
		case "RM_NEW":
		case "SCOPED_NEW":
		case "INAB_NEW":
			return "#E5E5FF";
		case "DR_INPRG":
		case "LEG_INPRG":
		case "RM_INPRG":
		case "SCOPED_INPRG":
			return "#FFEBC9"; // Change to appropriate color
		case "LEG_REV":
		case "DR_INREV":
		case 'LEG_VLDT':
		case "RM_REV":
		case "DR_PEND":
			return "#E2F5FF"; // Change to appropriate color
		case "DR_CMPLT":
		case "LEG_CMPLT":
		case "RM_CMPLT":
		case "SCOPED_CMPLT":
		case "INAB_COMPLETED":
			return "#DEF8EE"; // Change to appropriate color
		case "LEG_CUST_APVD":
		case "RM_APVD":
		case "DR_APVD":
		case "INAB_APPROVED":
			return "#314281";
		case "LEG_CUST_REJ":
		case "RM_REJ":
		case "DR_REJ":
		case "INAB_REJECTED":
			return "#FFC7C7";
		case "DR_REOPEN":
			return "#FFC7C7";
		case "RM_REOPEN":
			return "#FFC7C7";
		case "Full-Time":
			return "#FF5C5CCC";
		case "Contractor":
			return "#F6AC19A6";
		case "Active":
			return "#5776E7";
		case "hard commit":
			return "rgba(54, 78, 159, 1)";
		case "soft commit":
			return "rgba(89, 168, 212, 1)";
		case "Cancelled":
			return "rgba(28, 28, 28, 0.05)";
		case "medium commit":
				return "rgba(89, 168, 212, 1)";
		case "Pending":
			return "rgba(226, 245, 255, 1)";
		case "Completed":
			return "rgba(222, 248, 238, 1)";
		case "LEG_CUST_REV":
			return "#FF5C5CCC";
		default:
			return "black"; // Default color
	}
};

// Function to determine color based on status
const getStatusColor = (status, variant, theme, statusType) => {
	if (statusType === "stage") {
		if (status.includes("DR")) {
			//Deal Review
			return "#704C9F";
		} else if (status.includes("LEG")) {
			// Legal
			return "#54565B";
		} else if (status.includes("RM")) {
			// Resource Management
			return "#704C9F";
		} else if (status.includes("F")) {
			// Finance
			return "#8E2062";
		} else if (status.includes("INAB")) {
			// INAB
			return "#9747FF";
		} else {
			return "#36C5F0"; // Scoped
		}
	} else if (statusType === "model") {
		if (status.includes("DR")) {
			//Deal Review
			return "rgba(112, 76, 159, 1)";
		} else if (status.includes("LEG")) {
			// Legal
			return "rgba(112, 76, 159, 1)";
		} else if (status.includes("SCOPED")) {
			// Scoped
			return "#36C5F0";
		} else if (status.includes("RM")) {
			// Resource Management
			return "rgba(112, 76, 159, 1)";
		}
	} else {
		switch (status) {
			case "LEG_NEW":
			case "DR_NEW":
			case "RM_NEW":
			case "SCOPED_NEW":
			case "INAB_NEW":
				return variant === "contain" ? "#8A8CD9" : "#364E9F";
			case "DR_INPRG":
			case "LEG_INPRG":
			case "RM_INPRG":
			case "SCOPED_INPRG":
				return variant === "contain" ? "#FFAC21" : "#364E9F"; // Change to appropriate color
			case "DR_INREV":
			case "LEG_REV":
			case 'LEG_VLDT':
			case "RM_REV":
			case "DR_PEND":
				return variant === "contain" ? "#59A8D4" : "#59A8D4"; // Change to appropriate color
			case "DR_CMPLT":
			case "LEG_CMPLT":
			case "RM_CMPLT":
			case "SCOPED_CMPLT":
			case "INAB_COMPLETED":
				return variant === "contain" ? "#4AA785" : "#4CAF50"; // Change to appropriate color

			case "LEG_CUST_APVD":
			case "LEG_CUST_REV":
			case "RM_APVD":
			case "DR_APVD":
			case "INAB_APPROVED":
				return variant === "contain" ? "#FFFFFF" : "#00FFE0";
			case "LEG_CUST_REJ":
			case "RM_REJ":
			case "DR_REJ":
			case "INAB_REJECTED":
				return variant === "contain" ? "#FF5C5CCC" : "#FF5C5CCC";
			case "DR_REOPEN":
				return variant === "contain" ? "#FF5C5CCC" : "#FF5C5CCC";
			case "RM_REOPEN":
				return variant === "contain" ? "#FF5C5CCC" : "#FF5C5CCC";
			case "LEG_CUST_REV":
				return variant === "contain" ? "#FF5C5CCC" : "#FF5C5CCC";
			case "Allocated":
				return variant === "contain" ? "#FF5C5CCC" : "#4AA785";
			case "Not Allocated":
				return variant === "contain" ? "#FF5C5CCC" : "#FF5C5CCC";
			case "allocated":
				return variant === "contain" ? "#FF5C5CCC" : "#4AA785";
			case "not allocated":
				return variant === "contain" ? "#FF5C5CCC" : "#FF5C5CCC";
			case "in-active":
				return variant === "contain" ? "#FF5C5CCC" : "#FF5C5CCC";
			case "active":
				return variant === "contain" ? "#5776E7" : "#4AA785";
			case "Contractor":
				return variant === "contain" ? "#FFFFFF" : "#F6AC19A6";
			case "Full-Time":
				return variant === "contain" ? "#FFFFFF" : "#314281";
			case "Active":
				return variant === "contain" ? "#FFFFFF" : "rgba(246, 172, 25, 1)";
			case "Completed":
				return variant === "contain" ? "rgba(74, 167, 133, 1)" : "#4AA785";
			case "Pending":
				return variant === "contain" ? "rgba(89, 168, 212, 1)" : "#4AA785";
			case "Cancelled":
				return variant === "contain" ? "rgba(28, 28, 28, 0.4)" : "#4AA785";
			case "Activerm":
				return variant === "contain" ? "#FFFFFF" : "rgba(246, 172, 25, 1)";
			case "Inactive":
				return variant === "contain" ? "#FFFFFF" : "#FF5C5CCC";
			case "Deal Review":
				return "#704C9F";
			case "Resource Management":
				return "#704C9F";
			case "Completedrm":
				return "rgba(161, 227, 203, 1)";
			case "Pendingrm":
				return "rgba(89, 168, 212, 1)";
			case "INAB_COMPLETEDrm":
				return "rgba(161, 227, 203, 1)";
			case "INAB_REJECTEDrm":
				return variant === "contain" ? "#FF5C5CCC" : "#FF5C5CCC";
			case "INAB_APPROVEDrm":
			case "hard commit":
				return variant === "contain" ? "#FFFFFF" : " rgba(54, 78, 159, 1)"
			case "INAB_IN_FLIGHTrm":
			case "medium commit":
				return variant === "contain" ? "#FFFFFF" : "rgba(246, 172, 25, 1)"
			case "INAB_NEWrm":
			case "soft commit":
				return variant === "contain" ? "#FFFFFF" : "rgba(89, 168, 212, 1)"
			default:
				return theme?.palette?.text?.white; // Default color
		}
	}
};


const StatusBadge = ({ statusType = "", status, variant, color }) => {
	// Set default variant if not provided
	const selectedVariant = variant || "";

	// Error handling for invalid status
	if (!status || typeof status !== "string") {
		return <div>Error: Invalid status</div>;
	}

	return (
		<StatusBadgeContainer
			customColor={color}
			variant={variant}
			status={status}
			direction={"row"}>
			{selectedVariant === "contain" ? (
				<StatusMessage
					statusType={statusType}
					variant='h6'
					statusVariant={variant}
					status={status}>
					{getStatusText(status, variant, statusType)}
				</StatusMessage>
			) : (
				<>
					<Dot status={status} statusType={statusType} />
					<StatusMessage
						statusType={statusType}
						variant='h6'
						statusVariant={variant}
						status={status}>
						{getStatusText(status, variant, statusType)}
					</StatusMessage>
				</>
			)}
		</StatusBadgeContainer>
	);
};

// PropTypes for type checking
StatusBadge.propTypes = {
	status: PropTypes.string.isRequired,
	variant: PropTypes.oneOf(["contain", "dot"]),
};

export const getStatusText = (status, variant, statusType) => {
	if (statusType === "stage") {
		if (status.includes("DR")) {
			return "Deal Review";
		} else if (status.includes("LEG")) {
			return "Legal";
		} else if (status.includes("RM")) {
			return "RM";
		} else if (status.includes("FIN")) {
			return "Finance";
		} else if (status.includes('SCOPED')) {
			return "Scoped";
		} else if (status.includes('INAB')) {
			return "iNAB";
		} else {
			return status;
		}
	} else if (statusType === "model") {
		if (status.includes("DR")) {
			return "Deal Review";
		} else if (status.includes("LEG")) {
			return "Legal";
		} else if (status.includes("SCOPED")) {
			return "Scoped";
		} else if (status.includes("RM")) {
			return "RM";
		}
	} else {
		switch (status) {
			case "LEG_NEW":
			case "DR_NEW":
			case "RM_NEW":
			case "INAB_NEW":
				return variant === "contain" ? "New" : "Proposal";
			case "DR_INPRG":
			case "RM_INPRG":
			case "LEG_INPRG":
				return variant === "contain" ? "In-Progress" : "In Progress";
			case "LEG_REV":
			case "DR_INREV":
			case "RM_REV":
				return variant === "contain" ? "Review" : "In Review";
			case "DR_CMPLT":
			case "INAB_COMPLETED":
				return variant === "contain" ? "Completed" : "Completed";
			case "LEG_CMPLT":
				return variant === "contain" ? "Completed" : "Completed";
			case "RM_CMPLT":
				return variant === "contain" ? "Complete" : "Completed";
			case "LEG_VLDT":
				return "In Validation";
			case "LEG_CUST_REV":
				return "Customer Review";
			case "LEG_CUST_APVD":
			case "RM_APVD":
			case "DR_APVD":
			case "INAB_APPROVED":
				return "Approved";
			case "LEG_CUST_REJ":
			case "RM_REJ":
			case "DR_REJ":
			case "INAB_REJECTED":
				return "Rejected";
			case "DR_PEND":
				return "Pending";
			case "DR_REOPEN":
				return "Re-Open";
			case "RM_REOPEN":
				return "Re-Open";
			case "Allocated":
				return "Allocated";
			case "Deal Review":
				return "Deal Review";
			case "Resource Management":
				return "Resource Management";
			case "Active":
				return "Active";
			case "Activerm":
				return "In-Progress";
			case "SCOPED_NEW":
				return "New";
			case "SCOPED_INPRG":
				return "In Progress";
			case "SCOPED_CMPLT":
				return "Completed";
			case "Completedrm":
				return "Completed";
			case "Pendingrm":
				return "Pending";
			case "INAB_COMPLETEDrm":
				return "Completed";
			case "INAB_REJECTEDrm":
				return "Rejected";
			case "INAB_APPROVEDrm":
				return "Approved";
			case "INAB_NEWrm":
				return "New";
			case "INAB_IN_FLIGHTrm":
				return "In Flight";
			default:
				return status; // Default color
		}
	}
};

export default StatusBadge;
