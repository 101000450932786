import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    applicationPermission: [],
    menuPermission: {},
    pagePermission: {},
    menu:[],
    routes:[]
};

export const permissionSlice = createSlice({
    name: "permission",
    initialState,
    reducers: {
        setCurrentPagePermission: (state, action) => {
            state.pagePermission = action.payload;
        },
        resetCurrentPagePermission: (state) => {
            state.pagePermission = initialState.pagePermission;
        },
        setApplicationPermission: (state, action) => {
            state.applicationPermission = action.payload.permissions;
            state.menu = action.payload.menu;
            state.routes = action.payload.routes;
        },
        resetApplicationPermission: (state, action) => {
            state.applicationPermission = initialState.applicationPermission;
        },
        setMenuPermission: (state, action) => {
            const currentPagePermission = action.payload.currentMenuPermissions.otherRoutes[action.payload.processedPath]?.permissions || {};
            state.pagePermission = currentPagePermission;
            state.menuPermission = action.payload.currentMenuPermissions;
        },
        resetMenuPermission: (state, action) => {
            state.pagePermission = initialState.pagePermission;
        },
    },
});

export const { setCurrentPagePermission, resetCurrentPagePermission, setApplicationPermission, resetApplicationPermission, setMenuPermission, resetMenuPermission } = permissionSlice.actions;

export default permissionSlice.reducer;
