import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Grow,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import PlaceHolder from "../../assests/IconSet.svg";
import { convertDateFormat } from "../inputbox/ConvertDateFormat";
import useIntl from "../../common/lang/locale/en-US";
import StatusBadge from "../statusbadges/StatusBadge";
import { useNavigate, useParams } from "react-router-dom";
import { handleNavigation } from "../../utils/dashboardnavigation";
import SearchBar from "../dashboardcardsearch/Search";
import Nodata from "../Nodata";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ErrorIcon from "@mui/icons-material/Error";
import ShakingIcon from "../animation/ShakingIcon";
// import StatusBadge from "./StatusBadge";

export const CardGrid = styled(Grid)({
  gap: "1rem",
  // display: "flex",
  // flexDirection: "row",
  // flexWrap: "wrap",
  // gap: 28,
  // width: "100%",
});

export const CardItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  // flexBasis:'24%',
  // [theme.breakpoints.down('lg')]: {
  //   flexBasis: '33%',
  // },
}));

export const DetailsCard = styled(Stack)(({ theme }) => ({
  flex: "1 1 auto",
  width: "100%",
  height: "auto",
  padding: "24px",
  borderRadius: "16px",
  background: theme.palette.background.secondary,
  cursor: "pointer",
}));

export const TopCardContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "40px",
  gap: "20px",
  marginBottom: 8,
});

export const TextContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  gap: "0px",
});

export const Title = styled(Typography)({
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "20px",
  textAlign: "left",
});

export const Subtitle = styled(Typography)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: theme.palette.text.secondary,
  fontWeight: 400,
}));

export const FieldContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "0.5rem",
  columnGap: 12,
});

export const DealLabel = styled(Typography)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: theme.palette.text.secondary,
  fontWeight: 400,
}));

export const DealValue = styled(Typography)({
  textAlign: "right",
  whiteSpace: "nowrap" /* Prevent text from wrapping */,
  overflow: "hidden" /* Hide overflowing text */,
  textOverflow: "ellipsis" /* Show ellipsis for overflowed text */,
  fontWeight: 400,
});

export const Logo = styled("img")({
  width: "40px",
  height: "40px",
});

export const formatEngagementManager = (payload, count) => {
  // Check if the number of words is greater than the count
  if (payload?.length > count) {
    // Truncate the words array to the specified count and join them back into a string
    const truncatedName = payload.slice(0, count);
    return (
      <Tooltip title={payload}>
        <Typography variant="h7">{`${truncatedName}...`}</Typography>
      </Tooltip>
    );
  }
  // If not, return the original name
  return payload;
};

const DashboardCard = ({
  statusType = "",
  data = [],
  variant = "",
  showLeftBadge = false,
  isPreliminary = false,
  isINAP = false,
  isRm,
  children,
}) => {
  const navigation = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const { currentTab } = useParams();

  useEffect(() => {
    if (!data) return; // Return if data is not yet available
    const filtered = data?.filter(
      (item) =>
        item?.project_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.company_name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery, data]);

  const handleSearchChange = (value) => {
    setSearchQuery(value); // Update the search query state
  };
  return (
    <>
      <SearchBar
        placeholder="Search"
        onChange={handleSearchChange}
        showFilter={false}
      />
      <CardGrid container spacing={2} sx={{ gap:0}}>
        {filteredData?.length === 0 || filteredData === null ? (
          <Nodata />
        ) : (
          <>
          {filteredData?.map((item, index) => (
            <Grow in={true} mountOnEnter unmountOnExit style={{ transitionDelay: `${index * 50}ms` }}>
            <CardItem
              lg={3}
              md={4}
              sm={6}
              xs={12}
              item
              key={item.project_id || item.inab_number}
              id={`dashboard-card-${index}`}
              onClick={() => {
                handleNavigation({
                  projectId: item.project_id || item.inab_number,
                  status: item.hub_status,
                  navigation,
                  item,
                  currentTab,
                  isRm,
                });
              }}
            >
              <DetailsCard>
                <TopCardContainer>
                  <TextContainer>
                    <Title>
                      {formatEngagementManager(item?.project_name, 19)}
                    </Title>
                    <Subtitle variant="h6" sx={{ width: "20ch" }}>
                      {useIntl["dashboard.clientname"]}
                      {item?.company_name}
                    </Subtitle>
                  </TextContainer>
                  <Logo src={PlaceHolder} alt="Placeholder Icon" />
                </TopCardContainer>
                <TopCardContainer>
                  <StatusBadge
                    statusType={statusType}
                    variant={variant}
                    status={isRm ? "RM" : item.hub_status}
                  />
                  {showLeftBadge && (
                    <StatusBadge variant={"contain"} status={item.hub_status} />
                  )}
                </TopCardContainer>
                <FieldContainer direction="column">
                  <DealLabel variant="h6">
                    {isINAP
                      ? useIntl["dashboard.owner"]
                      : useIntl["dashboard.dealowner"]}
                  </DealLabel>
                  <DealValue variant="h6">{item.deal_owner}</DealValue>
                </FieldContainer>
                {isPreliminary && !isINAP ? (
                  <FieldContainer direction="column">
                    <DealLabel variant="h6">
                      {useIntl["dashboard.preliminarystatus"]}
                    </DealLabel>
                    <DealValue variant="h6">
                      {item.preliminary_staffing}
                    </DealValue>
                  </FieldContainer>
                ) : (
                  !isINAP && (
                    <FieldContainer direction="column">
                      <DealLabel variant="h6">
                        {useIntl["dashboard.createddate"]}
                      </DealLabel>
                      <DealValue variant="h6">
                        {convertDateFormat(item.created_date)}
                      </DealValue>
                    </FieldContainer>
                  )
                )}
                {!isINAP && (
                  <FieldContainer direction="column">
                    <DealLabel variant="h6">
                      {useIntl["dashboard.em"]}
                    </DealLabel>
                    <DealValue variant="h6">
                      {item.engagement_manager
                        ? formatEngagementManager(item.engagement_manager, 12)
                        : null}
                    </DealValue>
                  </FieldContainer>
                )}
                {isINAP && (
                  <FieldContainer direction="column">
                    <DealLabel variant="h6">
                      {useIntl["dashboard.subdate"]}
                    </DealLabel>
                    <DealValue variant="h6">{item.submitted_on}</DealValue>
                  </FieldContainer>
                )}
              </DetailsCard>
            </CardItem>
            </Grow>
          ))}
          {children}
          </>
        )}
      </CardGrid>
    </>
  );
};

export default DashboardCard;
