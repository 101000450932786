import useIntl from "../../common/lang/locale/en-US";
import CircleCheckIcon from "../../assests/CheckCircle.svg";

import CircleCheckDisable from "../../assests/CheckCircleDisable.svg";
import CheckCircleReject from "../../assests/CheckCircleReject.svg";
import ResultIcon from "../../assests/ResultIcon.svg";
import ResultIconReject from "../../assests/ResultIconReject.svg";
import ResultIconDisable from "../../assests/ResultIconDisable.svg";
import { Stack } from "@mui/material";
import { formatValue } from "./Valueformater";
import Round from "../Round";

const formatDate = (dateString) => {
  console.log("DS:", dateString);
  const date = new Date(dateString);
  console.log("date:", date);
  const formattedDate = date.toISOString().split("T")[0];
  return formattedDate;
};

const formatDateShort = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formatted = date.toLocaleDateString("en-US", options);
  return formatted;
};

// Function to handle possible errors and return "N/A" if value is missing, undefined, or null

export const getValueOrDefault = (
  value,
  isPreliminary = false,
  linkWrapper = () => {}
) => {
  if (value === "Yes" || value === "Accepted") {
    return isPreliminary ? (
      <Stack direction="row" alignItems="center">
        Completed
        {linkWrapper("View")}
      </Stack>
    ) : (
      "Completed"
    );
  } else if (value === "No" || value === "Rejected") {
    if (isPreliminary) return linkWrapper("Begin Preliminary Staffing");
    return "Rejected";
  } else if (value === "closed won") {
    return "Won";
  } else if (value === "closed lost") {
    return "Lost";
  } else {
    return "TBD";
  }
};

export const getValueorNA = (value) => {
  if (!value) {
    return "N/A";
  } else {
    return value;
  }
};

export const projectCardIcon = (value, isPreliminary = false) => {
  return value === undefined || value === null || value === "N/A"
    ? CircleCheckDisable
    : value === "Yes"
    ? CircleCheckIcon
    : isPreliminary
    ? CircleCheckDisable
    : CheckCircleReject;
};

export const OverViewDetails = (
  overViewData,
  isPreliminary = false,
  linkWrapper = () => {}
) => {
  console.log(overViewData);
  return {
    projectDetail: [
      {
        title: useIntl["deal_origin"],
        subtitle: getValueorNA(overViewData?.deal_origin),
      },
      {
        title: useIntl["deal_owner"],
        subtitle: getValueorNA(overViewData?.deal_owner),
      },
      {
        title: useIntl["deal_stage"],
        subtitle: getValueorNA(overViewData?.deal_stage),
      },
      {
        title: useIntl["est_close_date"],
        subtitle: overViewData?.est_close_date
          ? formatDateShort(overViewData?.est_close_date)
          : "N/A",
      },
      {
        title: useIntl["msa_complete"],
        subtitle: getValueOrDefault(overViewData?.msa_complete),
        icon: projectCardIcon(overViewData?.msa_complete),
      },
      {
        title: useIntl["sales_lead"],
        subtitle: getValueorNA(overViewData?.sales_lead),
      },
    ],
    status: overViewData?.hub_status,
    title: getValueorNA(overViewData?.project_name),
    statusSection: [
      {
        title: useIntl["prem_staffing_submitted"],
        subtitle: getValueOrDefault(
          overViewData?.prem_staffing_submitted,
          isPreliminary,
          linkWrapper
        ),
        icon: projectCardIcon(
          overViewData?.prem_staffing_submitted,
          isPreliminary
        ),
        date: "",
      },
      {
        title: useIntl["pmo_acceptance"],
        subtitle: getValueOrDefault(overViewData?.pmo_acceptance),
        icon: projectCardIcon(overViewData?.pmo_acceptance),
        date: "",
      },
      {
        title: useIntl["staffing_submitted"],
        subtitle: getValueOrDefault(overViewData?.staffing_submitted),
        icon: projectCardIcon(overViewData?.staffing_submitted),
        date: "",
      },
      {
        title: useIntl["customer_acceptance"],
        subtitle: getValueOrDefault(overViewData?.customer_acceptance),
        icon: projectCardIcon(overViewData?.customer_acceptance),
        date: "",
      },
      {
        title: useIntl["result"],
        subtitle: getValueOrDefault(overViewData?.deal_stage),
        icon:
          overViewData?.deal_stage == "closed won"
            ? ResultIcon
            : overViewData?.deal_stage === "closed lost"
            ? ResultIconReject
            : ResultIconDisable,
        date: "",
      },
    ],
    title2: useIntl["status"],
    tagsData: [
      {
        title: useIntl["technology"],
        technology: overViewData?.technology,
      },
      {
        title: useIntl["services_included"],
        service: overViewData?.services_included,
      },
    ],
    dealDescription: overViewData?.deal_description,
    additionalInfo: [
      {
        subLabel: useIntl["competency_lead"],
        value: getValueorNA(overViewData?.competency_lead),
        type: "edit",
        edit: false,
      },
      {
        subLabel: useIntl["practice_lead"],
        value: getValueorNA(overViewData?.practice_lead),
        type: "edit",
        edit: false,
      },
      {
        subLabel: useIntl["engagement_manager"],
        value: getValueorNA(overViewData?.engagement_manager),
        type: "edit",
        edit: false,
      },
      {
        subLabel: useIntl["delivery_manager"],
        value: getValueorNA(overViewData?.delivery_manager),
        type: "edit",
        edit: false,
      },
      {
        subLabel: useIntl["buying_process"],
        value: getValueorNA(overViewData?.buying_process),
        type: "view",
      },
      {
        subLabel: useIntl["contract_type"],
        value: getValueorNA(overViewData?.contract_type),
        type: "view",
      },
      {
        subLabel: useIntl["forecast_category"],
        value: getValueorNA(overViewData?.forecast_category),
        type: "view",
      },
    ],
    notes: overViewData?.notes,
    dealId: overViewData?.deal_review_id,
    project_id: overViewData?.project_id_var,
    hs_deal_id: overViewData?.hs_deal_id,
  };
};

export const LegalOverViewDetails = (overViewData) => {
  console.log("dataformatter:", overViewData);
  return {
    projectDetail: [
      {
        title: useIntl["project_name"],
        subtitle: getValueorNA(overViewData.project_name),
      },
      {
        title: useIntl["deal_origin"],
        subtitle: getValueorNA(overViewData.deal_origin),
      },
      {
        title: useIntl["deal_owner"],
        subtitle: getValueorNA(overViewData.deal_owner),
      },
      {
        title: useIntl["deal_stage"],
        subtitle: getValueorNA(overViewData.deal_stage),
      },
      {
        title: useIntl["est_close_date"],
        subtitle: formatDateShort(overViewData.est_close_date),
      },
      {
        title: useIntl["msa_complete"],
        subtitle: getValueOrDefault(overViewData.msa_complete),
        icon: CircleCheckIcon,
      },
      {
        title: useIntl["sales_lead"],
        subtitle: getValueorNA(overViewData.sales_lead),
      },
    ],
    status: overViewData.hub_status,
    title: getValueorNA(overViewData.project_name),
    statusSection: [
      {
        title: useIntl["prem_staffing_submitted"],
        subtitle: getValueOrDefault(overViewData.prem_staffing_submitted),
        icon: CircleCheckIcon,
        date: "10/10/2015",
      },
      {
        title: useIntl["pmo_acceptance"],
        subtitle: getValueOrDefault(overViewData.pmo_acceptance),
        icon: CircleCheckIcon,
        date: "10/10/2015",
      },
      {
        title: useIntl["staffing_submitted"],
        subtitle: getValueOrDefault(overViewData.staffing_submitted),
        icon: CircleCheckIcon,
        date: "10/10/2015",
      },
      {
        title: useIntl["customer_acceptance"],
        subtitle: getValueOrDefault(overViewData.pmo_acceptance),
        icon: CircleCheckIcon,
        date: "10/10/2015",
      },
      {
        title: useIntl["result"],
        subtitle: getValueOrDefault(overViewData.deal_review_complete),
        icon: ResultIcon,
        date: "10/10/2015",
      },
    ],
    title2: useIntl["status"],
    tagsData: [
      { title: useIntl["technology"], technology: overViewData.technology },
      {
        title: useIntl["services_included"],
        service: overViewData.services_included,
      },
    ],
    dealDescription: overViewData.deal_description,
    additionalInfo: [
      {
        subLabel: useIntl["competency_lead"],
        value: overViewData.competency_lead,
      },
      { subLabel: useIntl["practice_lead"], value: overViewData.practice_lead },
      {
        subLabel: useIntl["engagement_manager"],
        value: overViewData.engagement_manager,
      },
      {
        subLabel: useIntl["delivery_manager"],
        value: overViewData.delivery_manager,
      },
      {
        subLabel: useIntl["buying_process"],
        value: overViewData.buying_process,
      },
      { subLabel: useIntl["contract_type"], value: overViewData.contract_type },
      {
        subLabel: useIntl["forecast_category"],
        value: overViewData.forecast_category,
      },
    ],

    notes: overViewData.notes,
    dealId: overViewData.deal_review_id,
    project_id: overViewData.project_id_var,
    hs_deal_id: overViewData.hs.deal_id,
  };
};

const subtitle = (value) => {
  return value !== null && value !== undefined
    ? typeof value !== "number"
      ? isNaN(Number(value))
        ? "N/A"
        : value % 1 !== 0
        ? `$${Number(value).toFixed(2)}`
        : `$${Number(value)}`
      : value % 1 !== 0
      ? `$${value.toFixed(2)}`
      : `$${value}`
    : "N/A";
};

export const drFinalReviewData = ({ overView = {}, allPricingType }) => {
  const overViewData = overView ? overView : {};
  const {
    projectDetail,
    pricing_type,
  } = drStartOverview(overViewData);

  const selectedPricingObj = allPricingType?.filter(
    (itm) => itm?.pricing_type_id === pricing_type
  )?.[0];

  const selectedPricingName = selectedPricingObj?.pricing_type_name || null;

  const marginsDetails = [
    {
      title: "Current Margin",
      subtitle: formatValue(overViewData?.current_margin || 0, "%"),
    },
    {
      title: "Expected Margin",
      subtitle: formatValue(overViewData?.expected_margin || 0, "%"),
    },
    {
      title: "Rev. Before Deductions",
      subtitle: formatValue(overViewData?.revenue_before_deductions || 0),
      date: "",
    },
    {
      title: "Rev. After Deductions",
      subtitle: formatValue(overViewData?.revenue_after_deductions || 0)
    },
    {
      title: "Final Gross Margin",
      subtitle: formatValue(overViewData?.final_gross_revenue || 0)
    },
    {
      title: "Monthly Payment",
      subtitle: formatValue(overViewData?.monthly_payment || 0)
    },
  ];

  return {
    projectDetail,
    overViewDashboardData: {
      est_start_date: formatDateShort(overViewData?.est_start_date),
      est_end_date: formatDateShort(overViewData?.est_end_date),
      est_close_date: formatDateShort(overViewData?.est_close_date),
      duration_in_weeks: overViewData?.duration_in_weeks,
      service: overViewData?.services_included,
      technology: overViewData?.technology,
      lifeCycle: overViewData?.life_cycle,
      pricing_type,
      pricingType: selectedPricingName || "NA",
      expenses: overViewData?.total_deal_expenses || 'NA',
      total_travel_expenses: overViewData?.total_travel_expenses || 'NA',
      fee_expenses: overViewData.total_deal_expenses,
      notes: overViewData.notes,
      clientmanaged: overViewData?.clientmanaged,
      marginData: marginsDetails.filter((itm) => {
        const overViewMargins = [
          "Final Gross Margin",
          "Current Margin",
          "Expected Margin",
        ];
        return overViewMargins.includes(itm.title);
      }),
    },
    marginsDetails,
    ListData: [
      [
        {
          staffingData: {
            name: "Staffing Split",
            value: [
              {
                title: "Onsite",
                subtitle: formatValue(overViewData?.onsite_staff_percent || 0, '%'),
              },
              {
                title: "Offshore",
                subtitle: formatValue(overViewData?.offshore_staff_percent || 0, '%')
              },
            ],
          },
        },
        {
          Util: {
            name: "Utilization & Discount",
            value: [
              // {
              //   title: "PL/Exec Utilization",
              //   subtitle: formatValue(overViewData?.total_pl_cost || 0)
              // },
              // {
              //   title: "CL Utilization",
              //   subtitle: formatValue(overViewData?.total_cl_cost || 0)
              // },
              {
                title: "Wavicle Discount",
                subtitle: formatValue(overViewData?.total_discount || 0)
              },
            ],
          },
        },
        // {
        //   InaBox: {
        //     name: "2-In-A-Box",
        //     value: [
        //       {
        //         title: "2-In-A-Box (EM)",
        //         subtitle: formatValue(overViewData?.["2-in-a-box-em"] || 0)
        //       },
        //       {
        //         title: "2-In-A-Box (DL)",
        //         subtitle: formatValue(overViewData?.["2-in-a-box-dl"] || 0)
        //       },
        //     ],
        //   },
        {
          cost: {
            name: "Cost - Resources",
            subName: "Resources",
            value: [
              {
                title: "Total Wavicle Cost",
                subtitle: formatValue(overViewData?.total_wavicle_cost || 0),
              },
              {
                title: "Total Hours",
                subtitle: Round(overViewData?.total_hours_proposed || 0),
              },
              {
                title: "Total Client Price",
                subtitle: formatValue(overViewData?.total_client_price || 0),
              },
            ],
          },
        },
        // },
      ],
      [
        {
          expense: {
            name: "Expenses",
            subName: "Travel",
            value: [
              {
                title: "Total Travel",
                subtitle: formatValue(overViewData?.total_deal_expenses || 0),
              },
              {
                title: "Airfare",
                subtitle: formatValue(overViewData?.airfare || 0),
              },
              {
                title: "Hotels",
                subtitle: formatValue(overViewData?.hotel_stay_cost || 0),
              },
              {
                title: "Ground Transport",
                subtitle: formatValue(overViewData?.ground_transport || 0),
              },
              {
                title: "Meals",
                subtitle: formatValue(overViewData?.meal_expense || 0),
              },
              {
                title: "Others",
                subtitle: formatValue(overViewData?.other_expense || 0),
              },
            ],
          },
        },
      
      ],
      // Add more rows as needed
    ],
    status: overViewData.dr_status,
    title: overViewData.project_name,
    dealId: overViewData.deal_review_id, // Assuming DealreviewID is the deal ID
    project_id: overViewData.project_id_var,
    pricing_type: overViewData.pricing_type,
  };
};

export const drStartOverview = (overView) => {
  const overViewData = overView ? overView : {};

  return {
    projectDetail: [
      {
        title: useIntl["company_name"],
        subtitle: getValueorNA(overViewData?.company_name),
      },
      {
        title: useIntl["deal_owner"],
        subtitle: getValueorNA(overViewData?.deal_owner),
      },
      {
        title: useIntl["deal_stage"],
        subtitle: getValueorNA(overViewData?.deal_stage),
        date: "",
      },
      {
        title: useIntl["est_close_date"],
        subtitle: formatDateShort(overViewData?.est_close_date),
      },
      {
        title: useIntl["msa_complete"],
        subtitle: getValueOrDefault(overViewData?.msa_complete),
        icon: projectCardIcon(overViewData?.msa_complete),
      },
      {
        title: useIntl["sales_lead"],
        subtitle: getValueorNA(overViewData?.sales_lead),
      },
    ],
    service: [
      {
        title: useIntl["services_included"],
        labels: overViewData?.services_included
          ? overViewData?.services_included
          : [],
      },
    ],
    lifeCycle: [
      {
        title: useIntl["life_cycle"],
        labels: overViewData?.life_cycle ? overViewData?.life_cycle : [],
      },
    ],
    technology: [
      {
        title: useIntl["technology"],
        labels: overViewData?.technology ? overViewData?.technology : [],
      },
    ],

    status: overViewData.dr_status,
    title: overViewData.project_name,
    notes: overViewData.notes,
    dealId: overViewData.deal_review_id, // Assuming DealreviewID is the deal ID
    project_id: overViewData.project_id_var,
    fee_expenses: overViewData.total_deal_expenses,
    total_travel_expenses: overViewData.other_expense,
    pricing_type: overViewData.pricing_type,
  };
};

// export const DealReviewStartOverview = (overViewData) => {
// 	console.log("dataformatter:", overViewData);
// 	return {
// 		projectDetail: [
// 			{ title: useIntl["company_name"], subtitle: overViewData?.company_name },
// 			{ title: useIntl["deal_owner"], subtitle: overViewData?.deal_owner },
// 			{
// 				title: useIntl["deal_stage"],
// 				subtitle: overViewData?.deal_stage,
// 				date: "",
// 			},
// 			{
// 				title: useIntl["est_close_date"],
// 				subtitle: formatDateShort(overViewData?.est_close_date),
// 			},
// 			{
// 				title: useIntl["msa_complete"],
// 				subtitle: overViewData?.msa_complete,
// 				icon: CircleCheckIcon,
// 			},
// 			{ title: useIntl["sales_lead"], subtitle: overViewData?.sales_lead },
// 		],
// 		tagsData: [
// 			{ title: useIntl["technology"], technology: overViewData?.technology },
// 			{
// 				title: useIntl["services_included"],
// 				service: overViewData?.services_included,
// 			},
// 			{ title: useIntl["life_cycle"], service: overViewData?.life_cycle },
// 		],
// 		status: overViewData?.hub_status,
// 		title: overViewData?.project_name,
// 		notes: overViewData?.notes,
// 		dealId: overViewData?.deal_review_id,
// 		project_id: overViewData?.project_id_var,
// 		fee_expenses: overViewData?.fee_expenses,
// 		total_travel_expenses: overViewData?.total_travel_expenses,
// 		pricing_type: overViewData?.pricing_type,
// 	};
// };

export const DealReviewStartStaffIntake = (roleCardsData) => {
  return {
    dealId: roleCardsData.deal_review_id,
    staff_sheet_id: roleCardsData.staff_sheet_id,
    sow_role_name: roleCardsData.sow_role_name,
    wavicle_role_id: roleCardsData.wavicle_role_id,
    band: roleCardsData.band,
    is_offshore: roleCardsData.is_offshore,
    actual_rate: roleCardsData.actual_rate,
    utilization_percent: roleCardsData.utilization_percent,
    cost_utilization_percent: roleCardsData.cost_utilization_percent,
    no_of_resources: roleCardsData.no_of_resources,
    start_date: roleCardsData.start_date,
    end_date: roleCardsData.end_date,
    proposed_hours: roleCardsData.proposed_hours,
    indv_client_std_price: roleCardsData.indv_client_std_price,
    indv_actual_revenue: roleCardsData.indv_actual_revenue,
    indv_revenue_after_deductions: roleCardsData.indv_revenue_after_deductions,
    indv_wavicle_cost: roleCardsData.indv_wavicle_cost,
    indv_discount_cost: roleCardsData.indv_discount_cost,
    indv_pl_cost: roleCardsData.indv_pl_cost,
    indv_cl_cost: roleCardsData.indv_cl_cost,
    resource_type: roleCardsData.resource_type,
    required_skills: roleCardsData.required_skills,
    client_managed: roleCardsData.client_managed,
    laptop_needed: roleCardsData.laptop_needed,
    interview_panel: roleCardsData.interview_panel || [],
    role_requirements: roleCardsData.role_requirements,
    have_client_interview: roleCardsData.have_client_interview,
    client_interview_info: roleCardsData.client_interview_info,
    technology: roleCardsData.technology,
  };
};
