import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  InputBase,
  IconButton,
  Container,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
  Stack,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "@mui/icons-material/FilterList";
import FilterModal from "../modal/FilterModal";
import { CustomSelect } from "../autocomplete/SearChableSelect";
import { formatEngagementManager } from "../cards/DashBoardCard";

// Styled components for customizing Material-UI components
const StyledContainer = styled(Container)(({ theme, withoutInput }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: withoutInput ? "flex-end" : "space-between",
  padding: 9,
  gap: 16,
  borderRadius: 8,
  marginBlock: 16,
  background: theme.palette.background.secondary,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "389px",
  height: "28px",
  padding: "4px 8px",
  borderRadius: 8,
  background: theme.palette.background.primary,
  border: `1px solid ${theme.palette.borderColor}`,
  fontSize: 14,
  // "&::placeholder": {
  // 	background: "#1C1C1C33", // Placeholder background color
  // },
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  width: "18.02px",
  height: "18.02px",
  top: "1.48px",
  left: "1.48px",
  gap: "0px",
  opacity: 0,
  color: theme.palette.text.secondary,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: "36px",
  height: "36px",
  color: theme.palette.primary.dark,
  "&:hover": {
    color: "#1C1C1C55",
  },
}));

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

// Main SearchBar component
const SearchBar = ({
  placeholder = "Search",
  hideSearchInput = false,
  options = false,
  showFilter = true,
  onChange,
  FilterComponent = <></>,
  ShowHideComponent = <></>,
  handleClickFilterButton = () => { },
  table,
  enableExternalColumnFilters,
  setColumnSearchFilter,
  columnSearchFilter,
  hidePopup = () => { },
  isFilterOpen,
  searchValueProps,
  showStringOnly = false,
}) => {

  const [searchValue, setSearchValue] = useState(searchValueProps || "");
  const [loading, setLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 700);

  useEffect(() => {
    onChange(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
  };

  const handleOpenFilterModal = async (e) => {
    setLoading(true);
    await handleClickFilterButton(e);
    hidePopup(true);
    setLoading(false);
  };

  const takeStringValues = () => {
    if (!table || typeof table.getVisibleFlatColumns !== 'function') {
      return [];
    }
    const stringValues = table.getVisibleFlatColumns().filter((column) => {
      const header = column.columnDef.header;
      // Check if the header is a string and not a date
      return typeof header === 'string' && isNaN(Date.parse(header));
    }).map((column) => column.columnDef.header);
    return stringValues;
  };

  const dropdownData = showStringOnly ? takeStringValues() : (table?.getVisibleFlatColumns() || []);

  return (
    <StyledContainer withoutInput={hideSearchInput} maxWidth="100%">
      {!hideSearchInput && (
        <Stack direction="column">
          {enableExternalColumnFilters && (
            <Typography variant="h6">
              Search by column
            </Typography>
          )}
          <Stack direction="row" alignItems="center">
            {enableExternalColumnFilters && (
              <CustomSelect
                sx={{
                  width: "100px",
                  height: '28px',
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                  borderRight: '0px',
                  display: 'flex', // Ensures proper layout
                  alignItems: 'center',
                }}
                value={columnSearchFilter}
                placeholder="Select"
                onChange={(event) => {
                  setColumnSearchFilter(event.target.value)
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      boxShadow: 'none', // Removes the shadow
                      borderWidth: '0.1px',
                      border: '0.1px solid #ccc', // Adds a border
                    },
                  },
                }}
                renderValue={(selectedValue) => ( // Render selected value with ellipsis
                  <Typography
                    variant="body3"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '80px', // Adjust as needed
                      textTransform: 'capitalize',
                    }}
                  >
                    {formatEngagementManager(selectedValue, 9)}
                  </Typography>
                )}
              >
                {!showStringOnly ? dropdownData.map((column) => (
                  !(column.columnDef.header === 'View') &&
                  <MenuItem key={column.id} value={column.columnDef.header}>
                    <Typography variant="body2">{column.columnDef.header}</Typography>
                  </MenuItem>
                )): dropdownData.map((column) => (
                  !(column === 'View') &&
                  <MenuItem key={column.id} value={column}>
                    <Typography variant="body2">{column}</Typography>
                  </MenuItem>
                  ))}
              </CustomSelect>
            )}
            <StyledInputBase
              sx={{ borderTopLeftRadius: enableExternalColumnFilters ? '0px' : '8px', borderBottomLeftRadius: enableExternalColumnFilters ? '0px' : '8px', width: '200px' }}
              id="search"
              placeholder={placeholder}
              startAdornment={<StyledSearchIcon />}
              value={searchValue}
              onChange={handleInputChange}
            />
          </Stack>
        </Stack>
      )}
      {options && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                id="showOnlyAvailableResources"
                sx={{
                  "&.Mui-checked": {
                    color: "common.black",
                  },
                }}
                defaultChecked
                size="small"
              />
            }
            label={
              <Typography variant="h5">
                Show only available resources
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                id="autoRecommend"
                sx={{
                  "&.Mui-checked": {
                    color: "common.black",
                  },
                }}
                defaultChecked
                size="small"
              />
            }
            label={<Typography variant="h5">Auto-Recommend</Typography>}
          />
        </>
      )}
      <Box sx={{ position: "relative", display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
        {showFilter && (
          <Stack sx={{ height: '30px', width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {
              loading ? <CircularProgress size={16} color="inherit" /> :
                <StyledIconButton id="filter" onClick={handleOpenFilterModal}>
                  <FilterIcon />
                </StyledIconButton>
            }
          </Stack>
        )}

        <FilterModal
          overflowY={false}
          width="fit-content"
          open={isFilterOpen}
          handleClose={() => {
            hidePopup && typeof hidePopup === 'function' && hidePopup(false)
          }}
        >
          {FilterComponent}
        </FilterModal>
        {ShowHideComponent}
      </Box>
    </StyledContainer>
  );
};

export default SearchBar;
